@import '../../../styles/variables';
@import '../../../styles/media-queries';

.HeroBanner {
  background: #22a39f;
  color: white;
  font-family: $font-body;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 16px;
  height: 100vh;
  position: relative;
  min-height: 400px;

  .particles {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }

  .content {
    position: absolute;
    top: 30%;

    h1.name {
      font-weight: 700;
      font-size: 3.5rem;
      margin-bottom: 10px;
      padding: 25px 100px;
      border: 2px solid rgba(255, 255, 255, 0.35);

      @include phone-only {
        padding: 25px 50px;
        font-size: 2rem;
      }
    }

    h3.title {
      // font-family: $font-code;
      font-weight: 300;
      font-size: 1.75rem;
      margin-top: 15px;

      @include phone-only {
        font-size: 1.25rem;
      }
    }
  }
}
