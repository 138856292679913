@import '../../../styles/variables';
@import '../../../styles/media-queries';

.Skills {
  background: white;
  color: #727878;
  font-family: $font-body;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 16px 16px 48px 16px;
}