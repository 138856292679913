@import '../../../styles/variables';
@import '../../../styles/media-queries';

.Profile {
  background: #F3EFE0;
  color: #727878;
  font-family: $font-body;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 16px 16px 48px 16px;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}