@import '../../../../styles//variables';
@import '../../../../styles/media-queries';

.Skills-content {
  display: flex;
  flex-direction: column;
  width: 550px;
  margin-bottom: 25px;

  .skill {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(45, 47, 47, 0.2);

    padding: 5px 0;
    &:hover {
      background: #efefef;
      
      .skill-score {
        font-size: 1.075rem;
        font-weight: bold;
      }
    }

    .skill-title {
      width: 70%;
      padding: 10px;
      text-align: left;
    }

    .skill-score {
      width: 30%;
      padding: 10px;
      text-align: right;
      color: #22a39f;
      transition: all 0.2s cubic-bezier(.25,.8,.25,1);
    }
  }
}