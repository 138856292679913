@import '../../styles/variables';
@import '../../styles/media-queries';

.Title {
  font-family: $font-body;
  font-weight: 300;
  font-size: 3rem;
  margin-bottom: 10px;
  color: #22a39f;

  @include phone-only {
    font-size: 2rem;
  }
}