@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800&display=swap);
@import url(https://fonts.googleapis.com/css?family=Overpass+Mono:300,400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,800,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800&display=swap);
@import url(https://fonts.googleapis.com/css?family=Overpass+Mono:300,400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,800,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800&display=swap);
@import url(https://fonts.googleapis.com/css?family=Overpass+Mono:300,400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,800,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800&display=swap);
@import url(https://fonts.googleapis.com/css?family=Overpass+Mono:300,400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,800,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800&display=swap);
@import url(https://fonts.googleapis.com/css?family=Overpass+Mono:300,400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,800,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800&display=swap);
@import url(https://fonts.googleapis.com/css?family=Overpass+Mono:300,400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,800,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800&display=swap);
@import url(https://fonts.googleapis.com/css?family=Overpass+Mono:300,400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,800,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800&display=swap);
@import url(https://fonts.googleapis.com/css?family=Overpass+Mono:300,400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,800,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800&display=swap);
@import url(https://fonts.googleapis.com/css?family=Overpass+Mono:300,400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,800,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800&display=swap);
@import url(https://fonts.googleapis.com/css?family=Overpass+Mono:300,400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,800,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800&display=swap);
@import url(https://fonts.googleapis.com/css?family=Overpass+Mono:300,400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,800,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800&display=swap);
@import url(https://fonts.googleapis.com/css?family=Overpass+Mono:300,400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,800,900&display=swap);
.HeroBanner {
  background: #22a39f;
  color: white;
  font-family: "Nunito Sans", sans-serif;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 16px;
  height: 100vh;
  position: relative;
  min-height: 400px; }
  .HeroBanner .particles {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0; }
  .HeroBanner .content {
    position: absolute;
    top: 30%; }
    .HeroBanner .content h1.name {
      font-weight: 700;
      font-size: 3.5rem;
      margin-bottom: 10px;
      padding: 25px 100px;
      border: 2px solid rgba(255, 255, 255, 0.35); }
      @media (max-width: 576px) {
        .HeroBanner .content h1.name {
          padding: 25px 50px;
          font-size: 2rem; } }
    .HeroBanner .content h3.title {
      font-weight: 300;
      font-size: 1.75rem;
      margin-top: 15px; }
      @media (max-width: 576px) {
        .HeroBanner .content h3.title {
          font-size: 1.25rem; } }

.Title {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 300;
  font-size: 3rem;
  margin-bottom: 10px;
  color: #22a39f; }
  @media (max-width: 576px) {
    .Title {
      font-size: 2rem; } }

.Subtitle {
  font-weight: 300;
  font-size: 1.375rem;
  margin-top: 0px;
  width: 50%;
  color: #727878; }
  @media (max-width: 576px) {
    .Subtitle {
      font-size: 0.9725rem;
      width: 90%; } }
  @media (min-width: 576px) and (max-width: 767px) {
    .Subtitle {
      font-size: 1.125rem;
      width: 100%; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .Subtitle {
      font-size: 1.125rem;
      width: 75%; } }

.SecondaryTitle {
  font-weight: 300;
  font-size: 1.75em;
  margin-top: 0px;
  color: #22a39f;
  text-align: left;
  width: 100%;
  margin-bottom: 15px; }
  @media (max-width: 576px) {
    .SecondaryTitle {
      font-size: 1.25rem; } }
  @media (min-width: 576px) and (max-width: 767px) {
    .SecondaryTitle {
      font-size: 1.35rem; } }

.Experience {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
  padding: 48px;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  margin-bottom: 48px;
  width: 100%;
  border-radius: 3px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }
  .Experience:hover {
    -webkit-transform: translateY(-0.5rem) scale(1.01);
            transform: translateY(-0.5rem) scale(1.01);
    box-shadow: 0 1.5rem 2rem rgba(0, 0, 0, 0.15); }
  @media (max-width: 576px) {
    .Experience {
      padding: 16px; } }
  .Experience .company .far {
    margin-right: 5px; }
  .Experience .company h2 {
    font-family: "Nunito Sans", sans-serif;
    font-size: 1.5rem;
    margin-top: 0px;
    margin-bottom: 0px;
    font-weight: 700;
    color: #666; }
    @media (max-width: 576px) {
      .Experience .company h2 {
        font-size: 1.125rem; } }
  .Experience .company h3 {
    font-family: "Nunito Sans", sans-serif;
    font-size: 1.125rem;
    margin-top: 0px;
    margin-bottom: 8px;
    font-weight: 600; }
    @media (max-width: 576px) {
      .Experience .company h3 {
        font-size: 1rem; } }
  .Experience .company p {
    font-family: "Nunito Sans", sans-serif;
    font-size: 1rem;
    margin-top: 0px;
    font-weight: 600;
    color: #22a39f; }
    @media (max-width: 576px) {
      .Experience .company p {
        font-size: 0.925rem; } }
  .Experience .desc h3 {
    font-family: "Nunito Sans", sans-serif;
    font-size: 1.125rem;
    margin-top: 0px;
    margin-bottom: 5px;
    font-weight: 700; }
  .Experience .desc p {
    line-height: 1.60;
    font-family: "Nunito Sans", sans-serif;
    font-size: 1rem; }
    @media (max-width: 576px) {
      .Experience .desc p {
        font-size: 0.925rem; } }
  .Experience .desc .skills {
    display: flex;
    flex-direction: column; }
  .Experience .desc .key-tech-title {
    font-family: "Nunito Sans", sans-serif;
    font-size: 1.05rem;
    margin-top: 40px;
    margin-bottom: 0px;
    font-weight: 600; }
  .Experience .desc .tech-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center; }
    @media (max-width: 576px) {
      .Experience .desc .tech-list {
        justify-content: center; } }
    .Experience .desc .tech-list .icon-container {
      display: flex;
      flex-direction: column;
      padding: 16px 16px 4px 0px;
      align-items: center; }
      .Experience .desc .tech-list .icon-container .tech-title {
        text-align: center;
        font-size: 0.875rem; }
      .Experience .desc .tech-list .icon-container .redux-icon {
        height: 32px;
        margin-bottom: 8px; }
      .Experience .desc .tech-list .icon-container .webpack-icon {
        width: 39px;
        margin-bottom: 1px; }
    .Experience .desc .tech-list .fab {
      font-size: 2.25rem;
      margin-bottom: 4px; }
    .Experience .desc .tech-list .fas {
      font-size: 1.95rem;
      margin-bottom: 8px; }
    .Experience .desc .tech-list .fa-react {
      color: #00d8ff; }
    .Experience .desc .tech-list .fa-js {
      color: #f0db4f; }
    .Experience .desc .tech-list .fa-html5 {
      color: #e34c26; }
    .Experience .desc .tech-list .fa-css3-alt {
      color: #31a1d9; }
    .Experience .desc .tech-list .fa-git-alt {
      color: #f34f29; }
    .Experience .desc .tech-list .fa-database {
      color: #333333; }
  .Experience .icons {
    display: flex; }
    @media (max-width: 576px) {
      .Experience .icons {
        flex-direction: column; } }
    .Experience .icons .fas {
      color: #22a39f;
      margin-right: 5px; }
    .Experience .icons .location {
      margin-right: 10px;
      font-family: "Nunito Sans", sans-serif;
      font-size: 1rem;
      color: #22a39f; }
      @media (max-width: 576px) {
        .Experience .icons .location {
          margin-bottom: 5px;
          font-size: 0.925rem; } }
    .Experience .icons a {
      color: #22a39f;
      text-decoration: none;
      font-family: "Nunito Sans", sans-serif;
      font-size: 1rem;
      -webkit-transition: all 0.25s;
      transition: all 0.25s; }
      .Experience .icons a:hover {
        color: #2c8885; }
      @media (max-width: 576px) {
        .Experience .icons a {
          font-size: 0.925rem; } }

.Profile {
  background: #F3EFE0;
  color: #727878;
  font-family: "Nunito Sans", sans-serif;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 16px 16px 48px 16px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }

.Summary {
  background: white;
  color: #727878;
  font-family: "Nunito Sans", sans-serif;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 16px 16px 48px 16px; }
  .Summary .profile-content {
    display: flex;
    flex-direction: column; }
    .Summary .profile-content .description {
      line-height: 1.5;
      font-size: 1.125rem; }
      @media (max-width: 576px) {
        .Summary .profile-content .description {
          font-size: 1rem; } }
      .Summary .profile-content .description .location {
        color: #22a39f; }
      .Summary .profile-content .description .fa {
        color: #22a39f; }
  .Summary .button {
    margin-top: 10px;
    padding: 12px 28px;
    border: 1px solid transparent;
    background: #f56e4a;
    color: white;
    font-weight: 600;
    -webkit-transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
    transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
    text-transform: capitalize;
    font-size: 12px;
    text-decoration: none;
    align-self: center;
    cursor: pointer;
    letter-spacing: 1px;
    text-transform: uppercase;
    border-radius: 20px; }
    .Summary .button:hover {
      background: #f45b32;
      color: white; }
    @media (max-width: 576px) {
      .Summary .button {
        padding: 9px 20px;
        font-size: 11px; } }

.Skills-content {
  display: flex;
  flex-direction: column;
  width: 550px;
  margin-bottom: 25px; }
  .Skills-content .skill {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(45, 47, 47, 0.2);
    padding: 5px 0; }
    .Skills-content .skill:hover {
      background: #efefef; }
      .Skills-content .skill:hover .skill-score {
        font-size: 1.075rem;
        font-weight: bold; }
    .Skills-content .skill .skill-title {
      width: 70%;
      padding: 10px;
      text-align: left; }
    .Skills-content .skill .skill-score {
      width: 30%;
      padding: 10px;
      text-align: right;
      color: #22a39f;
      -webkit-transition: all 0.2s cubic-bezier(0.25, 0.8, 0.25, 1);
      transition: all 0.2s cubic-bezier(0.25, 0.8, 0.25, 1); }

.Skills {
  background: white;
  color: #727878;
  font-family: "Nunito Sans", sans-serif;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 16px 16px 48px 16px; }

.Social p {
  margin: 10px 0; }

.Social .fab {
  font-size: 2rem;
  margin-right: 15px;
  color: #727878;
  -webkit-transition: ease-in-out 0.15s;
  transition: ease-in-out 0.15s; }
  .Social .fab:hover {
    color: #22a39f; }

.Contact {
  background: #F3EFE0;
  color: #727878;
  font-family: "Nunito Sans", sans-serif;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 16px 16px 48px 16px; }
  .Contact .profile-content {
    display: flex;
    flex-direction: column; }
    .Contact .profile-content p {
      margin: 10px 0; }
    .Contact .profile-content .email {
      width: 100%;
      font-size: 1.25rem; }
      @media (max-width: 576px) {
        .Contact .profile-content .email {
          font-size: 1.125rem; } }


.nav {
  width: 100%;
  font-family: "Nunito Sans", sans-serif;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  /* ================================= 
    Media Queries
  ==================================== */ }
  .nav ul {
    margin: 0;
    padding: 0;
    list-style: none; }
  .nav h2,
  .nav h3,
  .nav a {
    color: #34495e; }
  .nav a {
    text-decoration: none;
    cursor: pointer; }
  .nav .logo {
    margin: 0;
    font-size: 1.45em; }
  .nav .main-nav {
    display: flex;
    justify-content: center; }
  .nav .logo a,
  .nav .main-nav a {
    padding: 10px 15px;
    text-transform: uppercase;
    text-align: center;
    display: block; }
  .nav .main-nav a {
    color: white;
    font-size: 15px;
    letter-spacing: 1px;
    font-weight: 600;
    text-transform: uppercase;
    -webkit-transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1); }
  .nav .main-nav a:hover {
    font-weight: 700; }
  .nav .header {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    background-color: rgba(34, 163, 159, 0.95); }
  @media (min-width: 769px) {
    .nav .header,
    .nav .main-nav {
      display: flex; }
    .nav .header {
      flex-direction: column;
      align-items: center; }
      .nav .header .header {
        width: 80%;
        margin: 0 auto;
        max-width: 1150px; } }
  @media (max-width: 576px) {
    .nav .header,
    .nav .main-nav {
      display: flex; }
      .nav .header a,
      .nav .main-nav a {
        font-size: 13px;
        letter-spacing: 0px; }
      .nav .header .logo,
      .nav .main-nav .logo {
        display: none; }
    .nav .header {
      flex-direction: column;
      align-items: center; }
      .nav .header .header {
        width: 80%;
        margin: 0 auto;
        max-width: 1150px; } }
  @media (min-width: 576px) and (max-width: 767px) {
    .nav .header,
    .nav .main-nav {
      display: flex; }
      .nav .header a,
      .nav .main-nav a {
        font-size: 14px; }
      .nav .header .logo,
      .nav .main-nav .logo {
        display: none; }
    .nav .header {
      flex-direction: column;
      align-items: center; }
      .nav .header .header {
        width: 80%;
        margin: 0 auto;
        max-width: 1150px; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .nav .header,
    .nav .main-nav {
      display: flex; }
      .nav .header .logo,
      .nav .main-nav .logo {
        display: none; }
    .nav .header {
      flex-direction: column;
      align-items: center; }
      .nav .header .header {
        width: 80%;
        margin: 0 auto;
        max-width: 1150px; } }
  .nav header .logo {
    height: 60px;
    width: 60px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin-left: 15px; }
  .nav header .button {
    padding: 9px 25px;
    border: 1px solid transparent;
    background: #f56e4a;
    color: white;
    margin-right: 10px;
    font-weight: 500;
    -webkit-transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
    transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
    text-transform: capitalize;
    font-size: 15px; }
    .nav header .button:hover {
      background: #f45b32;
      color: white; }
    @media (max-width: 576px) {
      .nav header .button {
        padding: 9px 20px;
        font-size: 14px; } }
  .nav header .fa-chevron-down {
    position: relative;
    top: 1px; }
  .nav header .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1; }
  .nav header .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block; }
  .nav header .dropdown-content a:hover {
    background-color: #ddd; }
  .nav header .dropdown:hover .dropdown-content {
    display: block; }

body {
  margin-top: 0px;
  margin-bottom: 0px; }
  body * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

