@import '../../../../styles//variables';
@import '../../../../styles/media-queries';

.Experience {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
  padding: 48px;
  background-color: rgba(white, 0.8);
  // box-shadow: 0 1.5rem 2rem rgba(#000000, 0.15);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  margin-bottom: 48px;
  width: 100%;
  border-radius: 3px;
  transition: all 0.3s;


  &:hover {
    transform: translateY(-0.5rem) scale(1.01);
  box-shadow: 0 1.5rem 2rem rgba(#000000, 0.15);
    // box-shadow: 0 2rem 2rem rgba(#000000, 0.2);
  }

  @include phone-only {
    padding: 16px;
  }

  .company {
    .far {
      margin-right: 5px;
    }

    h2 {
      font-family: $font-body;
      font-size: 1.5rem;
      margin-top: 0px;
      margin-bottom: 0px;
      font-weight: 700;
      color: #666;

      @include phone-only {
        font-size: 1.125rem;
      }
    }

    h3 {
      font-family: $font-body;
      font-size: 1.125rem;
      margin-top: 0px;
      margin-bottom: 8px;
      font-weight: 600;

      @include phone-only {
        font-size: 1rem;
      }
    }

    p {
      font-family: $font-body;
      font-size: 1rem;
      margin-top: 0px;
      font-weight: 600;
      color: #22a39f;

      @include phone-only {
        font-size: 0.925rem;
      }
    }
  }
  .desc {

    h3 {
      font-family: $font-body;
      font-size: 1.125rem;
      margin-top: 0px;
      margin-bottom: 5px;
      font-weight: 700;
    }

    p {
      line-height: 1.60;
      font-family: $font-body;
      font-size: 1rem;

      @include phone-only{
        font-size: 0.925rem;
      }
    }

    .skills {
      display: flex;
      flex-direction: column;
      // justify-content: center;
      // align-items: center;
    }

    .key-tech-title {
      font-family: $font-body;
      font-size: 1.05rem;
      margin-top: 40px;
      margin-bottom: 0px;
      font-weight: 600;
    }

    .tech-list {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      @include phone-only {
        justify-content: center;
      }

      .icon-container {
        display: flex;
        flex-direction: column;
        padding: 16px 16px 4px 0px;
        align-items: center;

        .tech-title {
          text-align: center;
          font-size: 0.875rem;
        }

        .redux-icon {
          height: 32px;
          margin-bottom: 8px;
        }

        .webpack-icon {
          width: 39px;
          margin-bottom: 1px;
        }
      }

      .fab {
        font-size: 2.25rem;
        margin-bottom: 4px;
      }

      .fas {
        font-size: 1.95rem;
        margin-bottom: 8px;
      }

      .fa-react {
        color: #00d8ff;
      }

      .fa-js {
        color: #f0db4f;
      }

      .fa-html5 {
        color: #e34c26;
      }

      .fa-css3-alt { 
        color: #31a1d9;
      }

      .fa-git-alt {
        color: #f34f29;
      }

      .fa-database {
        color: #333333;
      }
    }
  }

  .icons {
    display: flex;
    @include phone-only {
      flex-direction: column;
    }
    .fas {
      color: #22a39f;
      margin-right: 5px; 
    }
    .location {
      margin-right: 10px;
      font-family: $font-body;
      font-size: 1rem;
      color: #22a39f;

      @include phone-only {
        margin-bottom: 5px;
        font-size: 0.925rem;
      }
    }
    a {
      color: #22a39f;
      text-decoration: none;
      font-family: $font-body;
      font-size: 1rem;
      transition: all 0.25s;

      &:hover {
        color: #2c8885;
      }

      @include phone-only {
        font-size: 0.925rem;
      }
    }
  }
}