@import '../../../styles/variables';
@import '../../../styles/media-queries';

.Contact {
  background: #F3EFE0;
  color: #727878;
  font-family: $font-body;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 16px 16px 48px 16px;

  .profile-content {
    display: flex;
    flex-direction: column;

    p {
      margin: 10px 0;
    }

    .email {
      width: 100%;
      font-size: 1.25rem;

      @include phone-only {
        font-size: 1.125rem;
      }
    }

  }
}