@import '../../styles/variables';
@import '../../styles/media-queries';

.Social {
  p {
    margin: 10px 0;
  }
  .fab {
    // color: #22a39f;
    font-size: 2rem;
    margin-right: 15px;
    color: #727878;
    transition: ease-in-out 0.15s;

    &:hover {
      color: #22a39f;
    }
  }
}