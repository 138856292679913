@import '../../../styles/variables';
@import '../../../styles/media-queries';

.Summary {
  background: white;
  color: #727878;
  font-family: $font-body;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 16px 16px 48px 16px;

  .profile-content {
    display: flex;
    flex-direction: column;

    .description {
      line-height: 1.5;
      font-size: 1.125rem;

      @include phone-only {
        font-size: 1rem;
      }

      .location {
        color: #22a39f;
      }

      .fa {
        color: #22a39f;
      }
    }

  }


  .button {
    margin-top: 10px;
    padding: 12px 28px;
    border: 1px solid transparent;
    background: #f56e4a;
    color: white;
    font-weight: 600;
    transition: all 0.5s cubic-bezier(.25,.8,.25,1);
    text-transform: capitalize;
    font-size: 12px;
    text-decoration: none;
    align-self: center;
    cursor: pointer;
    letter-spacing: 1px;
    text-transform: uppercase;
    border-radius: 20px;

    &:hover {
      background: #f45b32;
      color: white;
    }

    @include phone-only {
      padding: 9px 20px;
      font-size: 11px;
    }
  }
}