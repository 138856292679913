@import '../../styles/variables';
@import '../../styles/media-queries';

.Subtitle {
  font-weight: 300;
  font-size: 1.375rem;
  margin-top: 0px;
  width: 50%;
  color: #727878;

  @include phone-only {
    font-size: 0.9725rem;
    width: 90%;
  }

  @include phablet-only {
    font-size: 1.125rem;
    width: 100%;
  }

  @include tablet-only {
    font-size: 1.125rem;
    width: 75%;
  }
}