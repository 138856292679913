@import '../../styles/variables';
@import '../../styles//media-queries';

.nav {
  width: 100%;
  font-family: $font-body;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  h2,
  h3,
  a {
    color: #34495e;
  }

  a {
    text-decoration: none;
    cursor: pointer;
  }

  .logo {
    margin: 0;
    font-size: 1.45em;
  }

  .main-nav {
    // margin-top: 5px;
    display: flex;
    justify-content: center;
  }
  .logo a,
  .main-nav a {
    padding: 10px 15px;
    text-transform: uppercase;
    text-align: center;
    display: block;
  }

  .main-nav a {
    color: white;
    font-size: 15px;
    letter-spacing: 1px;
    font-weight: 600;
    text-transform: uppercase;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  }

  .main-nav a:hover {
    font-weight: 700;
  }

  .header {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    background-color: rgba(34, 163, 159, 0.95);
  }

  /* ================================= 
    Media Queries
  ==================================== */

  @media (min-width: 769px) {
    .header,
    .main-nav {
      display: flex;
    }
    .header {
      flex-direction: column;
      align-items: center;
      .header {
        width: 80%;
        margin: 0 auto;
        max-width: 1150px;
      }
    }
  }

  @include phone-only {
    .header,
    .main-nav {
      display: flex;

      a {
        font-size: 13px;
        letter-spacing: 0px;
      }

      .logo {
        display: none;
      }
    }
    .header {
      flex-direction: column;
      align-items: center;
      .header {
        width: 80%;
        margin: 0 auto;
        max-width: 1150px;
      }
    }
  }

  @include phablet-only {
    .header,
    .main-nav {
      display: flex;
      a {
        font-size: 14px;
      }

      .logo {
        display: none;
      }
    }
    .header {
      flex-direction: column;
      align-items: center;
      .header {
        width: 80%;
        margin: 0 auto;
        max-width: 1150px;
      }
    }
  }

  @include tablet-only {
    .header,
    .main-nav {
      display: flex;

      .logo {
        display: none;
      }
    }
    .header {
      flex-direction: column;
      align-items: center;
      .header {
        width: 80%;
        margin: 0 auto;
        max-width: 1150px;
      }
    }
  }

  @media (min-width: 1025px) {
    .header {
      // flex-direction: row;
      // justify-content: space-between;
    }
  }

  header {
    .logo {
      height: 60px;
      // background: url('../../assets/krn-logo.PNG');
      width: 60px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      margin-left: 15px;
    }

    .button {
      padding: 9px 25px;
      border: 1px solid transparent;
      background: #f56e4a;
      color: white;
      margin-right: 10px;
      font-weight: 500;
      transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
      text-transform: capitalize;
      font-size: 15px;

      &:hover {
        background: #f45b32;
        color: white;
      }

      @include phone-only {
        padding: 9px 20px;
        font-size: 14px;
      }
    }

    .fa-chevron-down {
      position: relative;
      top: 1px;
    }

    .dropdown-content {
      display: none;
      position: absolute;
      background-color: #f1f1f1;
      min-width: 160px;
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
      z-index: 1;
    }

    .dropdown-content a {
      color: black;
      padding: 12px 16px;
      text-decoration: none;
      display: block;
    }

    .dropdown-content a:hover {
      background-color: #ddd;
    }

    .dropdown:hover .dropdown-content {
      display: block;
    }
  }
}
